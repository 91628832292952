import React from 'react';

const IconReservationAndWaitlist = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_0_138)">
      <path
        d="M12.7502 1.87689H10.1252V0.876892C10.1252 0.808142 10.069 0.751892 10.0002 0.751892H9.12524C9.05649 0.751892 9.00024 0.808142 9.00024 0.876892V1.87689H5.00024V0.876892C5.00024 0.808142 4.94399 0.751892 4.87524 0.751892H4.00024C3.93149 0.751892 3.87524 0.808142 3.87524 0.876892V1.87689H1.25024C0.973674 1.87689 0.750237 2.10033 0.750237 2.37689V12.7519C0.750237 13.0285 0.973674 13.2519 1.25024 13.2519H12.7502C13.0268 13.2519 13.2502 13.0285 13.2502 12.7519V2.37689C13.2502 2.10033 13.0268 1.87689 12.7502 1.87689ZM12.1252 12.1269H1.87524V3.00189H3.87524V3.75189C3.87524 3.82064 3.93149 3.87689 4.00024 3.87689H4.87524C4.94399 3.87689 5.00024 3.82064 5.00024 3.75189V3.00189H9.00024V3.75189C9.00024 3.82064 9.05649 3.87689 9.12524 3.87689H10.0002C10.069 3.87689 10.1252 3.82064 10.1252 3.75189V3.00189H12.1252V12.1269ZM9.75024 5.56439H8.88774C8.80805 5.56439 8.73149 5.60346 8.68461 5.66752L6.3268 8.91439L5.31586 7.52377C5.26899 7.45971 5.19399 7.42064 5.11274 7.42064H4.25024C4.14867 7.42064 4.0893 7.53627 4.14867 7.61908L6.12367 10.3378C6.14703 10.3698 6.17759 10.3958 6.21289 10.4137C6.24819 10.4316 6.28721 10.441 6.3268 10.441C6.36639 10.441 6.40541 10.4316 6.44071 10.4137C6.476 10.3958 6.50657 10.3698 6.52992 10.3378L9.8518 5.76439C9.91117 5.68002 9.8518 5.56439 9.75024 5.56439Z"
        fill="currentColor"
        fillOpacity="0.85"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_138">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconReservationAndWaitlist;
