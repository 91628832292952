import React from 'react';
import * as Icon from '@ant-design/icons';
import Link from 'next/link';
import { getItemName } from './utils';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { PagePayoutReport } from '../consts/routes';
import { FormattedMessage } from 'react-intl';
import NavMenuIcons from 'src/components/NavMenuIcons';

export function convertSystemPermissionTree({
  permissionTree = [],
  permissionTreeData = [],
  nameField = 'name',
  parentNodeId,
  checkIfDisabled = (nodeData) => false,
} = {}) {
  permissionTree
    .sort((a, b) => (a.order_num || 100) - (b.order_num || 100))
    .forEach((treeNode) => {
      const itemTreeData = {
        title: treeNode[nameField],
        key: treeNode.id,
        value: treeNode.id,
        children: [],
        parentNodeId,
        permissionCode: treeNode.permission_code,
        disabled: checkIfDisabled(treeNode),
      };
      permissionTreeData.push(itemTreeData);

      if (treeNode.sub_permission_tree && treeNode.sub_permission_tree.length) {
        itemTreeData.children = convertSystemPermissionTree({
          permissionTree: treeNode.sub_permission_tree,
          permissionTreeData: itemTreeData.children,
          parentNodeId: treeNode.id,
          nameField,
          checkIfDisabled,
        });
      }
    });

  return permissionTreeData;
}

export function getTreeDataCheckedAndExpandedKeys(
  treeData,
  { checkedKeys = [], permissionMapping = {}, permissionCodeToIdMapping = {} } = {}
) {
  for (let i = 0; i < treeData.length; i++) {
    const treeDataItem = treeData[i];
    const { id, has_required, permission_code, sub_permission_tree } = treeDataItem;
    permissionMapping[id] = permission_code;
    permissionCodeToIdMapping[permission_code] = id;

    if (has_required) {
      checkedKeys.push(permission_code);
    }

    if (sub_permission_tree && sub_permission_tree.length) {
      getTreeDataCheckedAndExpandedKeys(sub_permission_tree, {
        checkedKeys,
        permissionMapping,
        permissionCodeToIdMapping,
      });
    }
  }

  return { checkedKeys, permissionMapping, permissionCodeToIdMapping };
}

function getIcon(icon) {
  if (!icon) return null;
  let iconType = icon
    .split('-')
    .map((iconStr) => {
      return `${iconStr.slice(0, 1).toUpperCase()}${iconStr.slice(1)}`;
    })
    .join('');
  if (iconType in NavMenuIcons) {
    return <span className="anticon ant-menu-item-icon">{React.createElement(NavMenuIcons[iconType])}</span>;
  }
  iconType = `${iconType}Outlined`;
  if (Icon[iconType]) return React.createElement(Icon[iconType]);
  return null;
}

export function generateNavMenuData({ navMenu = [], rawMenuData, locale }) {
  rawMenuData.forEach((menuData) => {
    if (menuData.hideInMenu) return;

    const hasChildren = menuData.children && menuData.children.length;
    const menuItem = {
      key: menuData.path,
      icon: getIcon(menuData.icon),
      label: hasChildren ? (
        <span>{getItemName(menuData, locale)}</span>
      ) : (
        <Link href={menuData.path}>
          <a>
            <span>{getItemName(menuData, locale)}</span>
          </a>
        </Link>
      ),
    };

    // payout report menu has tooltip
    if (menuItem.key === PagePayoutReport) {
      menuItem.label = hasChildren ? (
        <Tooltip title={<FormattedMessage id="menu.transaction.report.title" />}>
          <span>{getItemName(menuData, locale)}</span>
        </Tooltip>
      ) : (
        <Link href={menuData.path}>
          <Tooltip title={<FormattedMessage id="menu.transaction.report.title" />} placement="right">
            {getItemName(menuData, locale)}
            <InfoCircleOutlined style={{ marginLeft: 4 }} />
          </Tooltip>
        </Link>
      );
    }
    navMenu.push(menuItem);

    if (hasChildren) {
      menuItem.children = generateNavMenuData({
        navMenu: [],
        rawMenuData: menuData.children,
        locale,
      });
    }
  });

  return navMenu;
}

/**
 * 从原始RG中找出能访问的餐厅及其上级RG
 * @type Restaurant = {
 *   id: number;
 *   name: string;
 *   foreign_name: string;
 * }
 * @type RestaurantGroupTreeNode = {
 *   id: number;
 *   name: string;
 *   restaurants: Restaurant[]
 *   children: RestaurantGroupTreeNode[]
 * }
 * @param {Object} params
 * @param {RestaurantGroupTreeNode[]} params.rawMenuData 原始RG树数据
 * @param {String} params.locale 当前语言
 * @param {RestaurantGroupTreeNode[]} params.navMenu 目标RG树
 * @param {String} params.prefixPath 当前遍历树节点路径 默认只为''
 * @param {Restaurant[]} params.restaurants 有权限的餐厅，便于后续搜索
 * @param {Number[]} params.iteratedRGIds 已遍历的RG ids，防止用户在父RG及子RG中分别设置后导致显示重复
 * @returns
 */
export function generateRestaurantGroupTree({
  rawMenuData = [],
  locale,
  navMenu = [],
  prefixPath = '',
  restaurants = [],
  iteratedRGIds = [],
}) {
  rawMenuData.forEach((menuData) => {
    if (iteratedRGIds.includes(menuData.id)) return;

    const hasSubMenus = menuData.children && menuData.children.length;
    const hasChildren = menuData.restaurants && menuData.restaurants.length;
    const isLeaf = !hasSubMenus && !hasChildren;
    if (!isLeaf) iteratedRGIds.push(menuData.id);

    const key = String(menuData.id);
    const path = [prefixPath, key].join('/');
    const _navMenu = [];
    const menuItem = {
      key,
      disabled: !isLeaf,
      isLeaf,
      path,
      children: _navMenu,
      name: menuData.name,
      foreign_name: menuData.foreign_name,
      title: getItemName(menuData, locale),
    };
    navMenu.push(menuItem);
    if (isLeaf) restaurants.push(menuItem);

    if (hasChildren) {
      generateRestaurantGroupTree({
        navMenu: _navMenu,
        rawMenuData: menuData.restaurants,
        locale,
        prefixPath: path,
        restaurants,
        iteratedRGIds,
      }).navMenu;
    }

    if (hasSubMenus) {
      generateRestaurantGroupTree({
        navMenu: _navMenu,
        rawMenuData: menuData.children,
        locale,
        prefixPath: path,
        restaurants,
        iteratedRGIds,
      });
    }
  });

  return { navMenu, restaurants };
}

export function generateGroupTree({
  rawMenuData,
  locale,
  navMenu = [],
  groupIds = [],
  allNodes = [],
  pId = '',
  pathIds = '',
  selGroup = false,
}) {
  rawMenuData.forEach((menuData) => {
    const isGroup = !!menuData.children;
    const hasRestaurants = menuData.restaurants && menuData.restaurants.length;
    const hasGroup = isGroup && menuData.children;

    const nodeKey = isGroup ? `${pathIds}/${menuData.id}_g` : `${pathIds}/${menuData.id}_r`;
    const treeNode = {
      key: nodeKey,
      id: menuData.id,
      parentId: pId,
      pathIds: nodeKey,
      name: menuData.name,
      disabled: selGroup && !!pId,
      foreign_name: menuData.foreign_name || '',
      title: `${getItemName(menuData, locale)}(${menuData.id})`,
      isLeaf: !isGroup || (!hasRestaurants && !hasGroup),
      isGroup,
      children: [],
    };
    navMenu.push(treeNode);
    allNodes.push(treeNode);

    if (isGroup) {
      groupIds.push(treeNode.key);

      if (menuData.children.length > 0) {
        generateGroupTree({
          rawMenuData: menuData.children,
          locale,
          navMenu: treeNode.children,
          pId: menuData.id,
          pathIds: treeNode.pathIds,
          selGroup,
          groupIds,
          allNodes,
        });
      }
      if (hasRestaurants) {
        generateGroupTree({
          rawMenuData: menuData.restaurants,
          locale,
          navMenu: treeNode.children,
          pId: menuData.id,
          pathIds: treeNode.pathIds,
          selGroup,
          groupIds,
          allNodes,
        });
      }
    }
  });

  return { navMenu, groupIds, allNodes };
}
