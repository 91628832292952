import React from 'react';

const IconDevice = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16268_2829)">
      <mask id="path-1-inside-1_16268_2829" fill="white">
        <rect x="7.68945" y="4.07544" width="7" height="9" rx="0.6" />
      </mask>
      <rect
        x="7.68945"
        y="4.07544"
        width="7"
        height="9"
        rx="0.6"
        stroke="currentColor"
        stroke-width="2"
        mask="url(#path-1-inside-1_16268_2829)"
      />
      <mask id="path-2-inside-2_16268_2829" fill="white">
        <path d="M3.09186 11.2754C3.09186 11.165 3.1814 11.0754 3.29186 11.0754H6.98911C7.04434 11.0754 7.08911 11.1202 7.08911 11.1754V11.9754C7.08911 12.0307 7.04434 12.0754 6.98911 12.0754H3.29186C3.1814 12.0754 3.09186 11.9859 3.09186 11.8754V11.2754Z" />
      </mask>
      <path
        d="M3.29186 12.0754H6.98911V10.0754H3.29186V12.0754ZM6.08911 11.1754V11.9754H8.08911V11.1754H6.08911ZM6.98911 11.0754H3.29186V13.0754H6.98911V11.0754ZM4.09186 11.8754V11.2754H2.09186V11.8754H4.09186ZM3.29186 11.0754C3.73369 11.0754 4.09186 11.4336 4.09186 11.8754H2.09186C2.09186 12.5382 2.62912 13.0754 3.29186 13.0754V11.0754ZM6.08911 11.9754C6.08911 11.4784 6.49205 11.0754 6.98911 11.0754V13.0754C7.59662 13.0754 8.08911 12.583 8.08911 11.9754H6.08911ZM6.98911 12.0754C6.49205 12.0754 6.08911 11.6725 6.08911 11.1754H8.08911C8.08911 10.5679 7.59663 10.0754 6.98911 10.0754V12.0754ZM3.29186 10.0754C2.62912 10.0754 2.09186 10.6127 2.09186 11.2754H4.09186C4.09186 11.7173 3.73369 12.0754 3.29186 12.0754V10.0754Z"
        fill="currentColor"
        mask="url(#path-2-inside-2_16268_2829)"
      />
      <mask id="path-4-inside-3_16268_2829" fill="white">
        <path d="M6.88911 9.07681C6.99957 9.07681 7.08911 9.16636 7.08911 9.27681L7.08911 11.6726C7.08911 11.7279 7.04434 11.7726 6.98911 11.7726L6.18911 11.7726C6.13388 11.7726 6.08911 11.7279 6.08911 11.6726L6.08911 9.27681C6.08911 9.16636 6.17865 9.07681 6.28911 9.07681L6.88911 9.07681Z" />
      </mask>
      <path
        d="M6.08911 9.27681L6.08911 11.6726L8.08911 11.6726L8.08911 9.27681L6.08911 9.27681ZM6.98911 10.7726L6.18911 10.7726L6.18911 12.7726L6.98911 12.7726L6.98911 10.7726ZM7.08911 11.6726L7.08911 9.27681L5.08911 9.27681L5.08911 11.6726L7.08911 11.6726ZM6.28911 10.0768L6.88911 10.0768L6.88911 8.07681L6.28911 8.07681L6.28911 10.0768ZM7.08911 9.27681C7.08911 9.71864 6.73094 10.0768 6.28911 10.0768L6.28911 8.07681C5.62637 8.07681 5.08911 8.61407 5.08911 9.27681L7.08911 9.27681ZM6.18911 10.7726C6.68617 10.7726 7.08911 11.1756 7.08911 11.6726L5.08911 11.6726C5.08911 12.2802 5.5816 12.7726 6.18911 12.7726L6.18911 10.7726ZM6.08911 11.6726C6.08911 11.1756 6.49206 10.7726 6.98911 10.7726L6.98911 12.7726C7.59662 12.7726 8.08911 12.2802 8.08911 11.6726L6.08911 11.6726ZM8.08911 9.27681C8.08911 8.61407 7.55185 8.07681 6.88911 8.07681L6.88911 10.0768C6.44728 10.0768 6.08911 9.71864 6.08911 9.27681L8.08911 9.27681Z"
        fill="currentColor"
        mask="url(#path-4-inside-3_16268_2829)"
      />
      <rect x="10.6895" y="10.4706" width="1" height="1" rx="0.5" fill="currentColor" />
      <path
        d="M1.68945 9.07544V2.07544H11.6895V3.37665C11.6895 3.43188 11.7342 3.47665 11.7895 3.47665H12.5895C12.6447 3.47665 12.6895 3.43188 12.6895 3.37665V1.67544C12.6895 1.34407 12.4208 1.07544 12.0895 1.07544H1.28945C0.958082 1.07544 0.689453 1.34407 0.689453 1.67544V9.47544C0.689453 9.80681 0.958082 10.0754 1.28945 10.0754H6.99066C7.04589 10.0754 7.09066 10.0307 7.09066 9.97544V9.17544C7.09066 9.12021 7.04589 9.07544 6.99066 9.07544H1.68945Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_16268_2829">
        <rect width="14" height="14" fill="white" transform="translate(0.689453 0.0754395)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconDevice;
