import React from 'react';

const IconSmartRestaurant = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75036 3.87215V3.12215C9.75036 3.0534 9.69411 2.99715 9.62536 2.99715H3.62536C3.55661 2.99715 3.50036 3.0534 3.50036 3.12215V3.87215C3.50036 3.9409 3.55661 3.99715 3.62536 3.99715H9.62536C9.69411 3.99715 9.75036 3.9409 9.75036 3.87215ZM3.62536 5.24715C3.55661 5.24715 3.50036 5.3034 3.50036 5.37215V6.12215C3.50036 6.1909 3.55661 6.24715 3.62536 6.24715H6.50036C6.56911 6.24715 6.62536 6.1909 6.62536 6.12215V5.37215C6.62536 5.3034 6.56911 5.24715 6.50036 5.24715H3.62536ZM9.50036 7.05965C7.6363 7.05965 6.12536 8.57059 6.12536 10.4347C6.12536 12.2987 7.6363 13.8097 9.50036 13.8097C11.3644 13.8097 12.8754 12.2987 12.8754 10.4347C12.8754 8.57059 11.3644 7.05965 9.50036 7.05965ZM11.18 12.1143C10.7316 12.5628 10.1347 12.8097 9.50036 12.8097C8.86598 12.8097 8.26911 12.5628 7.82067 12.1143C7.37223 11.6659 7.12536 11.069 7.12536 10.4347C7.12536 9.80028 7.37223 9.2034 7.82067 8.75496C8.26911 8.30653 8.86598 8.05965 9.50036 8.05965C10.1347 8.05965 10.7316 8.30653 11.18 8.75496C11.6285 9.2034 11.8754 9.80028 11.8754 10.4347C11.8754 11.069 11.6285 11.6659 11.18 12.1143ZM10.891 9.24715H10.1988C10.1582 9.24715 10.1207 9.2659 10.0972 9.29871L9.10505 10.6706L8.74411 10.1722C8.73255 10.1561 8.71729 10.143 8.69961 10.134C8.68194 10.125 8.66237 10.1204 8.64255 10.1206H7.95348C7.85192 10.1206 7.79255 10.2362 7.85192 10.319L9.00505 11.9143C9.05505 11.9831 9.15661 11.9831 9.20661 11.9143L10.991 9.44559C11.0519 9.36278 10.9925 9.24715 10.891 9.24715ZM5.87536 12.3097H2.25036V1.30965H11.0004V6.68465C11.0004 6.7534 11.0566 6.80965 11.1254 6.80965H12.0004C12.0691 6.80965 12.1254 6.7534 12.1254 6.68465V0.68465C12.1254 0.408088 11.9019 0.18465 11.6254 0.18465H1.62536C1.3488 0.18465 1.12536 0.408088 1.12536 0.68465V12.9347C1.12536 13.2112 1.3488 13.4347 1.62536 13.4347H5.87536C5.94411 13.4347 6.00036 13.3784 6.00036 13.3097V12.4347C6.00036 12.3659 5.94411 12.3097 5.87536 12.3097Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default IconSmartRestaurant;
