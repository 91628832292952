import React from 'react';

const IconKitchenDisplayScreen = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 1.1875H0.5C0.223437 1.1875 0 1.41094 0 1.6875V9.4375C0 9.71406 0.223437 9.9375 0.5 9.9375H6.4375V11.6875H3.75C3.6125 11.6875 3.5 11.8 3.5 11.9375V12.6875C3.5 12.7563 3.55625 12.8125 3.625 12.8125H10.375C10.4438 12.8125 10.5 12.7563 10.5 12.6875V11.9375C10.5 11.8 10.3875 11.6875 10.25 11.6875H7.5625V9.9375H13.5C13.7766 9.9375 14 9.71406 14 9.4375V1.6875C14 1.41094 13.7766 1.1875 13.5 1.1875ZM12.875 8.8125H1.125V2.3125H12.875V8.8125Z"
      fill="currentColor"
    />
  </svg>
);

export default IconKitchenDisplayScreen;
