import IconSmartRestaurant from './SmartRestaurant';
import IconCustomerDisplay from './CustomerDisplay';
import IconReservationAndWaitlist from './ReservationAndWaitlist';
import IconDevice from './Device';
import IconKitchenDisplayScreen from './KitchenDisplayScreen';

export default {
  SmartOrdering: IconSmartRestaurant,
  CustomerDisplay: IconCustomerDisplay,
  ReservationAndWaitlist: IconReservationAndWaitlist,
  Device: IconDevice,
  KitchenDisplayScreen: IconKitchenDisplayScreen,
};
