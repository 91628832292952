import React from 'react';

const IconCustomerDisplay = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.722 6.59782C12.2407 3.47751 10.0017 1.9072 7.00009 1.9072C3.99697 1.9072 1.75947 3.47751 0.278215 6.59938C0.218802 6.72519 0.187988 6.8626 0.187988 7.00173C0.187988 7.14086 0.218802 7.27826 0.278215 7.40407C1.75947 10.5244 3.99853 12.0947 7.00009 12.0947C10.0032 12.0947 12.2407 10.5244 13.722 7.40251C13.8423 7.14938 13.8423 6.85563 13.722 6.59782ZM7.00009 10.9697C4.47978 10.9697 2.63447 9.69157 1.3329 7.00095C2.63447 4.31032 4.47978 3.0322 7.00009 3.0322C9.5204 3.0322 11.3657 4.31032 12.6673 7.00095C11.3673 9.69157 9.52197 10.9697 7.00009 10.9697ZM6.93759 4.25095C5.41884 4.25095 4.18759 5.4822 4.18759 7.00095C4.18759 8.5197 5.41884 9.75095 6.93759 9.75095C8.45634 9.75095 9.68759 8.5197 9.68759 7.00095C9.68759 5.4822 8.45634 4.25095 6.93759 4.25095ZM6.93759 8.75095C5.9704 8.75095 5.18759 7.96813 5.18759 7.00095C5.18759 6.03376 5.9704 5.25095 6.93759 5.25095C7.90478 5.25095 8.68759 6.03376 8.68759 7.00095C8.68759 7.96813 7.90478 8.75095 6.93759 8.75095Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);

export default IconCustomerDisplay;
